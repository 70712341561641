import type { LinkExternal, LinkInternal } from '@backmarket/http-api'
import type {
  AlgoliaLink,
  AlgoliaProduct,
} from '@backmarket/http-api/src/api-specs-search-reco/search/searchAlgolia'
import type { PriceFacet } from '@backmarket/http-api/src/api-specs-search-reco/search/searchConfiguration'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'

interface FacetValue {
  count: number
  label: string
  name: string
  position: number
}

export interface Link {
  highlightedTitle?: string | undefined
  title: string
  link: LinkInternal | LinkExternal
  id: string
}

export const transformFacetsValues = (
  algoliaFacetValues: Record<string, number> | undefined,
  splitKeys = false,
): FacetValue[] =>
  algoliaFacetValues
    ? Object.entries(algoliaFacetValues)
        .map(([key, count]) => {
          const [position, ...words] = splitKeys ? key.split(' ') : ['0', key]

          return {
            position: parseInt(position, 10),
            label: words.join(' '),
            name: key,
            count,
          }
        })
        .filter((val) => val.count > 0)
        .sort((a, b) => a.position - b.position)
    : []

export function algoliaHitToVariant(hit: AlgoliaProduct): Product {
  return {
    availableStock: hit.stockRaw,
    brand: hit.brand,
    brandClean: hit.brand_clean,
    categoryId: hit.cat_id,
    category: hit.category_3,
    description: hit.sub_title_elements?.join(' - ') ?? '',
    grade: {
      name: hit.backbox_grade_label,
      value: hit.backbox_grade_value,
    },
    id: hit.id,
    objectId: hit.objectID,
    image: hit.image1,
    listingId: `${hit.listingID}`,
    model: hit.model,
    modelClean: hit.model_clean,
    price: { amount: `${hit.price}`, currency: hit.currency },
    priceNew: hit.referencePrice,
    productPageLink: hit.link_grade_v2,
    reviewRating: hit.reviewRating,
    sellerId: `${hit.merchant_id}`,
    title: hit.title_model,
    warrantyDuration: hit.warranty,
  }
}

export function algoliaHitToProduct(hit: AlgoliaProduct): Product {
  const specEntries = Object.entries(hit.variant_fields).map(
    ([key, specification]) => {
      const cleanSpec = specification
        .map((val) => {
          return typeof val === 'string' ? { name: val } : val
        })
        .filter((item) => item.name)

      return [key, cleanSpec]
    },
  )
  const specifications = Object.fromEntries(specEntries)

  return {
    id: hit.objectID,
    objectId: hit.objectID,
    title: hit.title_model,
    listingId: `${hit.listingID}`,
    model: hit.model,
    modelClean: hit.model_clean,
    brand: hit.brand,
    brandClean: hit.brand_clean,
    sellerId: `${hit.merchant_id}`,
    categoryId: hit.cat_id,
    category: hit.category_3,
    grade: {
      name: hit.backbox_grade_label,
      value: hit.backbox_grade_value,
    },
    productPageLink: hit.link_grade_v2,
    priceNew: hit.referencePrice,
    price: {
      amount: `${hit.price}`,
      currency: hit.currency,
    },
    reviewRating: hit.reviewRating,
    availableStock: hit.stockRaw,
    warrantyDuration: hit.warranty,
    image: hit.image1,
    specifications,
    scores: {
      lifeExpectancy: hit.life_expectancy_score,
      performances: hit.performances_score,
      camera: hit.camera_score,
      screenQuality: hit.screen_quality_score,
      soundQuality: hit.sound_quality_score,
      multimedia: hit.multimedia_score,
    },
  }
}

export function filtersToWhereClause(
  userFilters: Record<string, string[]>,
  initFilter = '',
): string {
  const result = Object.entries(userFilters).reduce(
    (acc: string[], [key, filterValue]) => {
      if (Array.isArray(filterValue) && filterValue.length > 0) {
        const sousChaine = filterValue
          .map((value) => value.replaceAll('"', '\\"'))
          // decodeURI(encodeURI()) ensure to get utf-8 encoded characters
          .map((value) => `${key}: "${decodeURI(encodeURI(value))}"`)
          .join(' OR ')
        acc.push(`(${sousChaine})`)
      } else if (typeof filterValue === 'string') {
        acc.push(`(${key}: "${filterValue}")`)
      }

      return acc
    },
    [],
  )

  result.push(initFilter)

  return result.filter((clause) => clause).join(' AND ')
}

export function hasValues(
  filterName: string,
  filterCollection: Record<string, string[]>,
): boolean {
  const values = filterCollection[filterName]

  return Array.isArray(values) && values.length > 0
}

const RANGE_BAR = 20
export function getGraphFacet(
  priceFacets: PriceFacet | undefined,
  algoliaFacets: Record<string, Record<string, number>> | undefined,
  algoliaFacetsValues: {
    avg: number
    max: number
  },
) {
  if (priceFacets && algoliaFacets && Object.keys(algoliaFacets).length) {
    const sortedScales = priceFacets.scales.toSorted(
      (a, b) => a.maxValue - b.maxValue,
    )
    const correctScale =
      sortedScales.find((scale) => scale.maxValue >= algoliaFacetsValues.max) ??
      sortedScales[sortedScales.length - 1]

    return {
      name: priceFacets.name,
      title: priceFacets.title,
      avg: algoliaFacetsValues.avg,
      max: correctScale.maxValue,
      values: Array.from(
        { length: RANGE_BAR },
        (_, i) => algoliaFacets?.[correctScale.facetName][i] ?? 0,
      ),
      step: Math.floor(correctScale.maxValue / RANGE_BAR),
    }
  }

  return undefined
}

export function algoliaHitToLink(hit: AlgoliaLink): Link {
  return {
    // eslint-disable-next-line no-underscore-dangle
    highlightedTitle: hit._highlightResult?.title.value,
    title: hit.title,
    link: hit.link,
    id: hit.objectID,
  }
}
